<template>
  <div class="base-dialog">
    <el-dialog
      v-model="visibleDialog"
      v-bind="$attrs"
      :destroy-on-close="isDestroy"
      :before-close="handleCancel"
      @close="$emit('close')"
      @closed="$emit('closed')"
      @open="$emit('open')"
      @opened="$emit('opened')"
    >
      <!-- 内容主题插槽 -->
      <slot name="main"></slot>

      <!-- 底部插槽 -->
      <template #footer>
        <span v-if="isShowButton">
          <el-button @click="handleCancel">取消</el-button>
          <el-button type="primary" @click="handleClick"> 提交 </el-button>
        </span>
        <span v-else class="dialog-footer">
          <slot name="mainFooter"></slot>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, withDefaults } from 'vue'

withDefaults(
  defineProps<{
    isShowButton?: boolean
    isDestroy?: boolean
  }>(),
  {
    isShowButton: true,
    isDestroy: false,
  }
)

const emits = defineEmits<{
  (e: 'click'): void
  (e: 'cancel'): void
  (e: 'close'): void
  (e: 'closed'): void
  (e: 'open'): void
  (e: 'opened'): void
}>()

const visibleDialog = ref(false) //控制弹框的显示与隐藏
// 取消按钮
const handleCancel = () => {
  visibleDialog.value = false
  emits('cancel')
}

// 确认按钮
const handleClick = () => {
  emits('click')
  visibleDialog.value = false
}

defineExpose({
  visibleDialog,
})
</script>

<script lang="ts">
export default {
  name: 'BaseDialog',
}
</script>

<style scoped lang="scss">
.base-dialog {
  :deep(.el-dialog__header) {
    display: none;
  }

  .footer {
  }
}
</style>
